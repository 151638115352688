import axios from 'axios';
import { ElMessage } from 'element-plus'
import router from "@/router";
// 创建 Axios 实例
const service = axios.create({
    // baseURL: 'https://api.guangsu111.com/api/bg', // 设置请求的基础路径
    // baseURL: 'http://127.0.0.1:9001/bg', // 设置请求的基础路径
    baseURL: 'https://api.shenhao.cfd/api/bg', // 设置请求的基础路径
    // baseURL: 'https://api.kuaidianmiaolaiyong.org/api/bg', // 设置请求的基础路径
    withCredentials:true
});
// const router = useRouter()

// // 请求拦截器
// service.interceptors.request.use(
//     config => {
//         // 在发送请求之前做些什么
//         // 比如添加请求头、身份验证等
//         config.headers['X-Custom-Header'] = 'foobar';
//         return config;
//     },
//     error => {
//         // 对请求错误做些什么
//         console.error('Request error:', error); // for debug
//         return Promise.reject(error);
//     }
// );

// 响应拦截器

    service.interceptors.response.use(
        response => {
            // 对响应数据做点什么
            return response.data;
        },
        async error => {
            // 对响应错误做点什么
            ElMessage.error(error);
            // console.error('Response error:', error); // for debug
            return Promise.reject(error);
        },
    );

    export const $http = (url,data,loading) => new Promise((resolve, reject) =>
    {
        service.post(url,data).then(res =>
        {
            if (res.status == 1)
            {
                resolve(res)
            }
            else if(res.status == -2)
            {

                ElMessage.error(res['exception'])
                // router.push('/sysLogin')
                router.replace({path:'/sysLogin'})
            }
            else
            {
                ElMessage.error(res['exception'])
                // reject(res['exception'])
                try {
                    loading.value = false
                }
                catch (e)
                {
                    // loading = false
                }

            }

        }).catch (err=>{
            // loading.value = false
            reject(err)
        })


    })





// axios.defaults.baseURL = 'http://127.0.0.1:5000/bg' // 设置请求的基础路径
// axios.defaults.timeout =  5000 // 设置请求超时时间
// axios.defaults.headers = {
//     // 'X-Requested-With':'XMLHttpRequest',
//     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
// };
//
//
// // eslint-disable-next-line no-unused-vars
// export function post(self,url,data){   return new Promise((resolve,reject) => {
//     axios.post(url,data)
//         .then(response => {
//             if(response.data.status === -1){
//                 message.error(response.data.exception);
//             }else if (response.data.status === 0) {
//                 message.error(response.data.exception);
//             }else{
//                 resolve(response.data);
//             }
//         },err => {
//             // reject(err);
//             // self.$message.error('服务器异常');
//             console.log(err)
//         })
// })
// }



export default service;