import { createApp } from 'vue';
import App from './App';
import router from "@/router";
const app = createApp(App);
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/dark/css-vars.css'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//网络请求引入
// import {post} from './api.js';


app.use(ElementPlus,{
    locale: zhCn,
}).use(router)
// app.config.globalProperties.$post=post;
app.mount('#app');

const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}