<template>
  <div style="padding: 20px">
    <div style="display: flex;justify-content: flex-end">
      <el-button type="primary" @click="addNoticeModal">添加联系方式</el-button>
    </div>
    <el-table stripe="true" border="true"  :data="noticeDataList" size="large" style="height: 85%;width: 100%;margin-top: 20px">
      <el-table-column label="ID" prop="id" width="100px" align="center"/>
      <el-table-column label="应用名称" prop="appname" width="200px" align="center"/>
      <el-table-column label="应用版本" prop="appversion" width="100px" align="center"/>
      <el-table-column label="内容" prop="gonggao"  align="center"/>
      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <el-button type="primary" style="margin-left: 20px" @click="changeNoticeModal(scope.row)"  >修改</el-button>
          <el-button type="danger" style="margin-left: 20px" @click="delNotice(scope.row.id)"  >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
  </div>

  <el-dialog
      v-model="modalVisible"
      width="600px"
      title="添加联系方式"
      centered
      :footer="null"
  >
    <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>应用名称:</span>
                <el-input v-model="modalName" placeholder="请输入应用名称" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>应用版本:</span>
                <el-input v-model="modalVersion" placeholder="请输入应用版本" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>内容:</span>
                <el-input v-model="modalContent" placeholder="请输入公告内容" style="width: 300px"
                          :autosize="{ minRows: 5, maxRows: 50 }"
                          type="textarea"/>
            </span>


      <div v-if="isFlash"/>
      <span class="DialogTv">
                 <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="this.modalVisible=false">取消</el-button>
                 <el-button type="danger" style="width: 40%" :loading="generateLinkLoading" @click="addNotice" danger>保存</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
      v-model="changeModalVisible"
      width="600px"
      title="修改联系方式"
      centered
      :footer="null"
  >
    <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>应用名称:</span>
                <el-input v-model="modalName" placeholder="请输入应用名称" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>应用版本:</span>
                <el-input v-model="modalVersion" placeholder="请输入应用版本" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>内容:</span>
                <el-input v-model="modalContent" placeholder="请输入公告内容" style="width: 300px"
                          :autosize="{ minRows: 5, maxRows: 50 }"
                          type="textarea"/>
            </span>


      <div v-if="isFlash"/>
      <span class="DialogTv">
                 <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="this.changeModalVisible=false">取消</el-button>
                 <el-button type="danger" style="width: 40%" :loading="generateLinkLoading" @click="changeNotice" danger>保存</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import {$http} from "@/api";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name:"ContactPage",
  data(){
    return {
      noticeDataList: [],
      currentPage: 1, //当前页码
      totalCount: 0, //服务器返回总数量
      modalVisible: false,//弹出窗
      modalId: '',
      modalName: '',
      modalVersion: '',
      modalContent: '',
      generateLinkLoading: false,
      changeModalVisible: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const formData = new FormData();
      $http('/get_contact_list', formData)
          .then(res => {
            this.noticeDataList = res['resource']
            this.totalCount = res['totalCount']
          })
    },
    addNoticeModal() {
      this.modalVisible = true
    },
    showSizeChange() {

    },
    addNotice() {

      // 判断所有资源
      if (this.modalName == '') {
        message.error('请输入应用名称')
        return
      }
      if (this.modalVersion == '') {
        message.error('清输入应用版本')
        return;
      }
      if (this.modalContent == '') {
        message.error('请输入内容')
        return;
      }

      const formData = new FormData();
      formData.append('name', this.modalName)
      formData.append('version', this.modalVersion)
      formData.append('content', this.modalContent)
      this.generateLinkLoading = true
      $http('/add_contact', formData)
          .then(res => {
            this.generateLinkLoading = false;
            this.modalVisible = false
            this.modalName = ''
            this.modalVersion = ''
            this.modalContent = ''

            ElMessage({
              message: '添加成功',
              type: 'success',
            })
            this.getList()
          })
    },
    changeNotice() {

      // 判断所有资源
      if (this.modalName == '') {
        message.error('请输入应用名称')
        return
      }
      if (this.modalVersion == '') {
        message.error('清输入应用版本')
        return;
      }
      if (this.modalContent == '') {
        message.error('请输入内容')
        return;
      }

      const formData = new FormData();
      formData.append('id', this.modalId)
      formData.append('name', this.modalName)
      formData.append('version', this.modalVersion)
      formData.append('content', this.modalContent)
      this.generateLinkLoading = true
      $http('/change_contact', formData)
          .then(res => {
            this.generateLinkLoading = false;
            this.changeModalVisible = false
            this.modalId = ''
            this.modalName = ''
            this.modalVersion = ''
            this.modalContent = ''

            ElMessage({
              message: '修改成功',
              type: 'success',
            })
            this.getList()
          })
    },
    changeNoticeModal(data) {
      this.modalId = data.id
      this.modalName = data.appname;
      this.modalVersion = data.appversion;
      this.modalContent = data.gonggao;
      this.changeModalVisible = true
    },
    delNotice(id) {
      let self = this;
      ElMessageBox.confirm(
          '确认删除该联系方式？该操作不可逆',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        const formData = new FormData()
        formData.append('id', id)

        $http('/del_contact', formData)
            .then(res => {
              ElMessage({
                message: '删除成功',
                type: 'success',
              })
              self.getList()
            })
      }).catch(() => {

      })
    }
  }
}
</script>
