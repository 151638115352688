<template>
  <div style="height: 100%">
    <div style="display: flex;justify-content: space-between;padding-top: 20px;padding-left: 20px;padding-right: 20px">
      <div style="width:10%;height:100px;background-color: #FFF;display: flex;justify-content: center;flex-direction: column;">
        <span style="font-size: 18px">总用户量</span>
        <span style="margin-top: 10px;font-size: 18px;font-weight: bold; ">{{zUser}}</span>
      </div>
      <div style="width:10%;height:100px;background-color: #FFF;display: flex;justify-content: center;flex-direction: column;">
        <span style="font-size: 18px">总会员数</span>
        <span style="margin-top: 10px;font-size: 18px;font-weight: bold; ">{{zPay}}</span>
      </div>
      <div style="width:10%;height:100px;background-color: #FFF;display: flex;justify-content: center;flex-direction: column;">
        <span style="font-size: 18px">今日日活</span>
        <span style="margin-top: 10px;font-size: 18px;font-weight: bold; ">{{todayLogin}}</span>
      </div>
      <div style="width:10%;height:100px;background-color: #FFF;display: flex;justify-content: center;flex-direction: column;">
        <span style="font-size: 18px">今日支付</span>
        <span style="margin-top: 10px;font-size: 18px;font-weight: bold; ">{{todayPay}}</span>
      </div>
      <div style="width:10%;height:100px;background-color: #FFF;display: flex;justify-content: center;flex-direction: column;">
        <span style="font-size: 18px">上月总收入</span>
        <span style="margin-top: 10px;font-size: 18px;font-weight: bold; ">{{lastPay}}</span>
      </div>
      <div style="width:10%;height:100px;background-color: #FFF;display: flex;justify-content: center;flex-direction: column;">
        <span style="font-size: 18px">本月总收入</span>
        <span style="margin-top: 10px;font-size: 18px;font-weight: bold; ">{{currentPay}}</span>
      </div>
    </div>
    <div style="display: flex;justify-content: space-between;padding-top: 100px">
      <div id="regChart" style="width: 50%;height:400px;"></div>
      <div id="typeChart" style="width: 50%;height:400px;"></div>

    </div>
    <div style="display: flex;justify-content: space-between;padding-top: 100px">
      <div id="payChart" style="width: 50%;height:400px;"></div>
      <div id="pieChart" style="width: 50%;height:400px;"></div>

    </div>

  </div>


</template>


<script>
import * as echarts from 'echarts'
import {$http} from "@/api";
let typeChart;
let regChart;
let payChart;
let pieChart;
export default {
    name: 'OverviewPage',
    data(){
      return{
        zUser : 0,
        zPay : 0,
        todayLogin: 0,
        todayPay: 0,
        lastPay:0,
        currentPay:0
      }
    },
    methods:{
      getList(){

        const formData = new FormData();
        // 顶部多项
        $http('/get_overview',formData,this.isLoading)
            .then(res =>{
                let resource = res['resource']
                this.zUser = resource.userNum
                this.zPay = resource.vipNum
                this.todayLogin = resource.loginNum
                this.todayPay = resource.todayPay
                this.lastPay = resource.lastMonthPay
                this.currentPay = resource.monthPay

            })

        // 注册折线图
        $http('/get_user_count',formData)
            .then(res=>{
              regChart.setOption({
                title: {
                  text: ''
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['注册量注册量','去重后注册量']
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                toolbox: {
                  feature: {
                    saveAsImage: {}
                  }
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: res.resource[0].dayList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.resource[0].data
              });
            })

        // 渠道折线图
        $http('/get_channel_user_count',formData)
            .then(res=>{
              typeChart.setOption({
                title: {
                  text: ''
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: res.resource[0].data
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                toolbox: {
                  feature: {
                    saveAsImage: {}
                  }
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: res.resource[0].dayList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.resource[0].data
              });
            })

        // 渠道支付柱状图
        $http('/get_channel_pay',formData)
            .then(res =>{
              payChart.setOption({
                title: {
                  text: ''
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: res.resource[0].data
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                toolbox: {
                  feature: {
                    saveAsImage: {}
                  }
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: res.resource[0].dayList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.resource[0].data
              });
            })

        // 渠道饼图
        $http('/get_channel_pie',formData)
            .then(res =>{
              pieChart.setOption({
                tooltip: {
                  trigger: 'item'
                },
                legend: {
                  top: '5%',
                  left: 'center'
                },
                series: [
                  {
                    name: '今日收入(元)',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                      borderRadius: 10,
                      borderColor: '#fff',
                      borderWidth: 2
                    },
                    label: {
                      show: false,
                      position: 'center'
                    },
                    emphasis: {
                      label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: 'bold'
                      }
                    },
                    labelLine: {
                      show: false
                    },
                    data: res.resource
                  }
                ]
              })
            })
      }
    },
    mounted() {
      let chardivom = document.getElementById("typeChart");
      chardivom.removeAttribute('_echarts_instance_')
      chardivom = document.getElementById("regChart");
      chardivom.removeAttribute('_echarts_instance_')
      chardivom = document.getElementById("payChart");
      chardivom.removeAttribute('_echarts_instance_')
      chardivom = document.getElementById("pieChart");
      chardivom.removeAttribute('_echarts_instance_')


      typeChart = echarts.init(document.getElementById('typeChart'))
      regChart = echarts.init(document.getElementById('regChart'))
      payChart = echarts.init(document.getElementById('payChart'))
      pieChart = echarts.init(document.getElementById('pieChart'))
      this.getList()

    }
}
</script>

