<template>
    <div style="height:100vh"  class="dark">
        <el-container style="height:100vh">
            <el-menu
                @select="tabClick"
                text-color="#FFFFFF"
                background-color="#242c30"
                style="height: 100vh;background-color:#283342 ;"
                default-active="1"
                class="el-menu-vertical-demo"
                :collapse="isCollapse"
                @open="handleOpen"
                @close="handleClose" >
                <div>
                    23423
                </div>
              <el-sub-menu index="0">
                <template #title>
                  <el-icon><Notification /></el-icon>
                  <span style="font-size: 15px;">系统信息</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="overviewPage" style="margin-left: 20px">数据概况</el-menu-item>
<!--                  <el-menu-item index="userPayList" style="margin-left: 20px">支付列表</el-menu-item>-->
                </el-menu-item-group>

              </el-sub-menu>
                <el-sub-menu index="1">
                    <template #title>
                        <el-icon><Notification /></el-icon>
                        <span style="font-size: 15px;">用户管理</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="userList" style="margin-left: 20px">用户列表</el-menu-item>
                        <el-menu-item index="userPayList" style="margin-left: 20px">支付列表</el-menu-item>
                        <el-menu-item index="UserFlowPage" style="margin-left: 20px">用户流量</el-menu-item>
                    </el-menu-item-group>

                </el-sub-menu>
                <el-sub-menu index="2">
                    <template #title>
                        <el-icon><Discount /></el-icon>
                        <span style="font-size: 15px;">节点管理</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="nodeInfo" style="margin-left: 20px">节点状态</el-menu-item>
                        <el-menu-item index="nodeList" style="margin-left: 20px">节点列表</el-menu-item>
                    </el-menu-item-group>
                </el-sub-menu>
                <el-sub-menu index="3">
                <template #title>
                  <el-icon><Discount /></el-icon>
                  <span style="font-size: 15px;">应用管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="noticePage" style="margin-left: 20px">公告管理</el-menu-item>
                  <el-menu-item index="contactPage" style="margin-left: 20px">联系我们</el-menu-item>
                  <el-menu-item index="payPage" style="margin-left: 20px">支付管理</el-menu-item>
                  <el-menu-item index="ChannelPage" style="margin-left: 20px">渠道管理</el-menu-item>
                  <el-menu-item index="AdPage" style="margin-left: 20px">广告管理</el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
            </el-menu>
            <el-container>
                <el-header style="display: flex;align-items: center">
                    <!-- 折叠按钮 -->
                    <el-button  style="font-size: 25px" size="large" :text="true" :icon="Fold" @click="isCollapse=!isCollapse"></el-button>
                </el-header>
                <el-main style="background-color: #eef0f3">
                    <div style="background-color: #FFF;height: 100%;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px">
                        <router-view></router-view>
                    </div>
                </el-main>
            </el-container>

        </el-container>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import {useRouter}  from "vue-router";
import {Fold} from "@element-plus/icons-vue";

const selectedKeys1 = ref(['2']);
const selectedKeys2 = ref();
const isCollapse =ref(true)
const openKeys = ref(['sub1']);
const router = useRouter()
const tabClick=(e,e2)=>
{
    router.push('/index/'+e)
}


</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>
<!--<style scoped>-->
<!--#components-layout-demo-top-side .logo {-->
<!--    float: left;-->
<!--    width: 120px;-->
<!--    height: 31px;-->
<!--    margin: 16px 24px 16px 0;-->
<!--    background: rgba(255, 255, 255, 0.3);-->
<!--}-->

<!--.ant-row-rtl #components-layout-demo-top-side .logo {-->
<!--    float: right;-->
<!--    margin: 16px 0 16px 24px;-->
<!--}-->

<!--.site-layout-background {-->
<!--    background: #fff;-->
<!--}-->
<!--</style>-->

<!--<template>-->
<!--    aa-->
<!--</template>-->