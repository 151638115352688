<template>
    <div style="padding: 20px;height: 100%">
        <div style="display: flex;align-items: center;">
            <el-input v-model="keyUserId" placeholder="请输入用户Id" style="width: 300px" allowClear="true"  clearable/>

          <span style="margin-left: 10px;font-size: 14px">渠道</span>
          <el-select ref="select" v-model="channel" style="width: 120px; margin-left: 20px" @focus="focus" @change="keyIsSysChange" >
            <el-option
                v-for="item in sysOptions"
                :key="item.value"
                :label="item.lable"
                :value="item.value"/>
          </el-select>
          <span style="margin-left: 10px;font-size: 14px">状态</span>
          <el-select ref="select" v-model="status" style="width: 120px; margin-left: 20px" @focus="focus" @change="keyIsStatusChange" >
            <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.lable"
                :value="item.value"/>
          </el-select>
            <el-button style="margin-left: 20px;width: 100px" type="primary" @click="getUserPayList">搜索</el-button>
        </div>
        <el-table stripe="true" border="true"  :data="transactionListData" size="large" style="height: 80%;width: 100%;margin-top: 20px">
            <el-table-column label="订单号" prop="transactionId" width="200px" align="center"/>
<!--            <el-table-column label="用户ID" prop="userId" width="300px" align="center"/>-->
            <el-table-column label="用户ID" prop="userId" width="350px" align="center">
              <template #default="scope">
                <el-link target="_blank" @click="router.push('/index/UserFlowPage?userId='+scope.row.userId)">{{scope.row.userId}}</el-link>
              </template>
            </el-table-column>
            <el-table-column label="开通时间" prop="vipOpenTime" width="200px" align="center"/>
            <el-table-column label="到期时间" prop="vipOutTime" width="200px" align="center"/>
            <el-table-column label="套餐" prop="vipType" width="100px" align="center">
                <template #default="scope">
                        <span v-if="scope.row.vipType == 'month'">
                            <el-tag type="success">月卡</el-tag>
                        </span>
                        <span v-else-if="scope.row.vipType == 'year'">
                           <el-tag type="success">年卡</el-tag>
                        </span>
                      <span v-else-if="scope.row.vipType == 'week'">
                            <el-tag type="success">周卡</el-tag>
                        </span>
                        <span v-else-if="scope.row.vipType == 'noyear'">
                            <el-tag type="success">半年卡</el-tag>
                        </span>
                        <span v-else-if="scope.row.vipType == 'season'">
                            <el-tag type="success">季卡</el-tag>
                        </span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" width="180px" align="center"/>
            <el-table-column label="渠道" prop="channel" width="160px" align="center"/>
          <el-table-column label="申请退款时间" prop="refundTime" width="200px" align="center"/>
          <el-table-column label="退款次数" prop="refunds" width="200px" align="center"/>

            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button type="danger" style="margin-left: 20px" @click="delPayItem(scope.row.id)"  >删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
    </div>

</template>
<script>
import {$http} from "@/api";
import { ElMessage } from 'element-plus'
import {ElMessageBox} from "element-plus";
import {useRouter} from "vue-router";

export default {
    name:'UserPayListPage',
    data(){
        return{
            transactionListData:[],
            currentPage:1, //当前页码
            totalCount:0, //服务器返回总数量
            keyUserId:'', // 搜索用ID
            channel:'-1', // 渠道选择
            status:'-1',//状态
            sysOptions:[{value:'-1',lable:"所有"}],
            statusOptions:[{value:'-1',lable:"所有"},{value:'正常',lable:"正常"},{value:'退款申请中',lable:"退款申请中"},{value:'已退款',lable:"已退款"},],
            router:useRouter()
        }
    },
    mounted(){
        // if()

        if(this.$route.query['userId'])
        {
            this.keyUserId = this.$route.query['userId']
        }
        this.getChannelList()
        this.getUserPayList()


    },
    methods:{
        // 获取列表
        getUserPayList(){
            const formData = new FormData();
            formData.append('currentPage', this.currentPage)
            formData.append('pageCount', '12');
            formData.append('userId',this.keyUserId)
            formData.append('channel',this.channel)
            formData.append('status',this.status)
            //

            $http('/get_transaction_list',formData)
                .then(res=>{

                    this.transactionListData = res['resource']
                    this.totalCount = res['totalCount']

                  for (let bean in this.transactionListData)
                  {
                      const f = new FormData()
                      f.append("id",this.transactionListData[bean].userId)

                      $http('/get_refunds',f)
                          .then(res=>{

                              this.transactionListData[bean]['refunds']=res['resource'][0]
                          })
                  }




                })

        },
        // 获取渠道列表
        getChannelList(){
          const formData = new FormData();
          $http('/get_channel',formData)
              .then(res=>{

                  let resource = res['resource']

                  this.sysOptions = []
                  this.sysOptions.push({value:'-1',lable:"所有"})
                  for (let bean in resource)
                  {
                      this.sysOptions.push({value:resource[bean].name,lable:resource[bean].name})
                  }
              })
        },
        // 页码回调
        showSizeChange(current,size){
            this.urrentPage = current
            this.getUserPayList()
        },
        // 渠道回调
        keyIsSysChange(value){
          this.channel = value
        },
        // 状态回调
        keyIsStatusChange(value)
        {
          this.status = value
        },
        // 删除订单
        delPayItem(id){
            let self = this;
            ElMessageBox.confirm(
                '确认删除该订单信息？该操作不可逆',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                const formData = new FormData()
                formData.append('id',id)

                $http('/del_transaction',formData)
                    .then(res=>
                    {
                        ElMessage({
                            message: '删除成功',
                            type: 'success',
                        })
                        self.getUserPayList()
                    })
            }).catch(() => {

            })

        }
    }
}
</script>