<template>
  <div style="padding: 20px">
    <div style="display: flex;align-items: center;">
      <el-input v-model="keyUserId" placeholder="请输入用户Id" style="width: 300px" allowClear="true"  clearable/>

      <el-button style="margin-left: 20px;width: 100px" type="primary" @click="getFlow">搜索</el-button>
    </div>
    <el-table stripe="true" border="true"  :data="flowList" size="large" style="height: 85%;width: 100%;margin-top: 20px">
      <el-table-column label="节点名称" prop="name" width="500px" align="center"/>
      <el-table-column label="上传流量" prop="u" width="500px" align="center"/>
      <el-table-column label="下载流量" prop="d" width="500px" align="center"/>
      <el-table-column label="总流量" prop="z"  align="center"/>

    </el-table>
    <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
  </div>

</template>

<script>
  import {$http} from "@/api";

  export default {
    name: 'UserFlowPage',
    data(){
        return{
          flowList:[],
          keyUserId:'',
          currentPage:1, //当前页码
          totalCount:0, //服务器返回总数量
        }
    },
    mounted(){
      // if()

      if(this.$route.query['userId'])
      {
        this.keyUserId = this.$route.query['userId']
        this.getFlow()
      }

    },
    methods:{
      //
      getFlow(){
        this.getFlowList(this.keyUserId)
      },
      getFlowList(id){
        const formData = new FormData();
        formData.append('id', id)
        $http('/get_user_ud',formData)
            .then(res =>{
              let returnList = []
              let resource = res['resource']
              for(let bean in resource)
              {
                let u = resource[bean]['u']
                let d = resource[bean]['d']
                let z = (Number(u) || 0) + (Number(d) || 0)
                if(u > 0){
                  u = u / 1024 / 1024
                }
                if(d >0)
                {
                  d = d / 1024 /1024
                }
                if(z >0)
                {
                  z = z / 1024 / 1024
                }

                returnList.push({
                  u:(Number(u) || 0).toFixed(2)+" MB",
                  d:(Number(d) || 0).toFixed(2)+" MB",
                  z:(Number(z) || 0).toFixed(2)+" MB",
                  name:resource[bean]['name']
                })
              }


              this.flowList = returnList
              this.totalCount = res['totalCount']
            })
      }
    }
  }
</script>
