import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import NodeListPage from "@/components/NodeListPage.vue";
import UserListPage from "@/components/UserListPage.vue";
import LoginPage from "@/components/LoginPage.vue";
import MainPage from "@/components/MainPage.vue";
import UserPayListPage from "@/components/UserPayListPage.vue";
import IndexPage from "@/components/IndexPage.vue";
import NodeInfoPage from "@/components/NodeInfoPage.vue";
import OverviewPage from "@/components/OverviewPage.vue";
import PayPage from "@/components/PayPage.vue";
import NoticePage from "@/components/NoticePage.vue";
import ContactPage from "@/components/ContactPage.vue";
import ChannelPage from "@/components/ChannelPage.vue";
import ChannelDataPage from "@/components/ChannelDataPage.vue";
import UserFlowPage from "@/components/UserFlowPage.vue";
import AdPage from "@/components/AdPage.vue";

// const routes = [
//     { path: '/index' ,component: MainPage },
//
//     { path: '/nodeList', component: NodeListPage },
//     { path: '/userList', component: UserListPage },
//
//
//     // { path:'/sysLogin', component: LoginPage}
//     // 其他路由...
// ];

const routes = [
    { path: '/index' ,component: MainPage ,children:
            [
                { path: 'overviewPage', component: OverviewPage },
                { path: 'nodeList', component: NodeListPage },
                { path: 'userList', component: UserListPage },
                { path: 'userPayList', component: UserPayListPage },
                { path: 'nodeInfo', component: NodeInfoPage},
                { path: 'payPage', component: PayPage },
                { path: 'noticePage', component: NoticePage },
                { path: 'contactPage', component: ContactPage },
                { path: 'ChannelPage', component: ChannelPage },
                { path: 'UserFlowPage', component: UserFlowPage },
                { path: 'adPage', component: AdPage },
            ]},
    {path :'/channelData', component: ChannelDataPage},
    { path:'/sysLogin', component: LoginPage},
    {path: '/',
        beforeEnter: (to, from, next) => {
            // 使用 window.location 来重定向到外部网站
            window.location.href = 'https://www.apache.org/licenses/';
        } }

    // 其他路由...
];


const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history:createWebHashHistory(),
    routes,
});

export default router;