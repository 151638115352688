<template>
  <div style="background-color: #FFF;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px;">
    <div style="height: 200px;width: 100%; padding: 20px;display: flex;justify-content: space-around">
      <div style="width:20%;height:100%;background-color: #FFF;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px;display: flex;justify-content: space-around;flex-direction: column;">
        <span style="font-size: 18px">总用户量 {{zUser}}</span>
        <span style="font-size: 18px">总会员数 {{zPay}}</span>
        <span style="font-size: 18px">今日日活 {{todayLogin}}</span>
        <span style="font-size: 18px">今日支付 {{todayPay}}</span>
      </div>

      <div style="width:35%;height:100%;background-color: #FFF;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px;">
        <div id="regChart" style="width: 100%;height:200px;"></div>
      </div>
      <div style="width:35%;height:100%;background-color: #FFF;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px;">
        <div id="payChart" style="width: 100%;height:200px;"></div>
      </div>
    </div>
    <span>用户数据</span>
    <div style="margin-top:10px;margin-left:60px;margin-bottom:20px;width:97%;height: 600px;background-color: #FFF;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px;">
      <el-table stripe="true" border="true"  :data="userListData" size="large" style="height: 90%;width: 100%;">
        <el-table-column label="用户名" prop="userName" width="150px" align="center"/>
        <el-table-column label="设备ID" prop="deviceId" width="400px" align="center"/>
        <el-table-column label="会员" width="150px" align="center">
          <template #default="scope">
              <span v-if="scope.row.isVip">
                  <el-tag type="warning">会员</el-tag>
              </span>
          </template>
        </el-table-column>
        <el-table-column label="开通日期" prop="vipOpenTime" width="200px" align="center"/>
        <el-table-column label="到期时间" prop="vipOutTime" width="200px" align="center"/>
        <el-table-column label="套餐" width="200px" align="center">
          <template #default="scope">
            <template v-if="scope.row.vipOpenTime != ''">
                  <span v-if="scope.row.vipType == 'month'">
                      <el-tag type="success">月卡</el-tag>
                  </span>
              <span v-else-if="scope.row.vipType == 'year'">
                      <el-tag type="success">年卡</el-tag>
                  </span>
              <span v-else-if="scope.row.vipType == 'noyear'">
                       <el-tag type="success">半年卡</el-tag>
                  </span>
              <span v-else-if="scope.row.vipType == 'season'">
                      <el-tag type="success">季卡</el-tag>
                  </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" prop="regisTime" align="center"/>
        <el-table-column label="最后登录时间" prop="loginTime" align="center"/>
        <el-table-column label="渠道" prop="channel" align="center"/>
      </el-table>
      <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showUserSizeChange" :page-size="10" />
    </div>
    <span>支付数据</span>
    <div style="margin-left:60px;width:97%;height: 600px;background-color: #FFF;box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);border-radius: 5px;">
      <el-table stripe="true" border="true"  :data="transactionListData" size="large" style="height: 85%;width: 100%;margin-top: 20px">
        <el-table-column label="订单号" prop="transactionId" width="350px" align="center"/>
        <el-table-column label="用户ID" prop="userId" width="350px" align="center"/>
        <el-table-column label="开通时间" prop="vipOpenTime" width="400px" align="center"/>
        <el-table-column label="到期时间" prop="vipOutTime" width="400px" align="center"/>
        <el-table-column label="套餐" prop="vipType" width="400px" align="center">
          <template #default="scope">
                      <span v-if="scope.row.vipType == 'month'">
                          <el-tag type="success">月卡</el-tag>
                      </span>
            <span v-else-if="scope.row.vipType == 'year'">
                         <el-tag type="success">年卡</el-tag>
                      </span>
            <span v-else-if="scope.row.vipType == 'noyear'">
                          <el-tag type="success">半年卡</el-tag>
                      </span>
            <span v-else-if="scope.row.vipType == 'season'">
                          <el-tag type="success">季卡</el-tag>
                      </span>
            <span v-else-if="scope.row.vipType == 'week'">
                          <el-tag type="success">周卡</el-tag>
                      </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="300px" align="center"/>
        <el-table-column label="渠道" prop="channel" width="300px" align="center"/>

      </el-table>
      <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="payTotalCount" v-model:current-page="payCurrentPage" @change="showPaySizeChange" :page-size="10" />
    </div>

  </div>
</template>

<script>

  import * as echarts from "echarts";
  import {ElMessage} from "element-plus";
  import {$http} from "@/api";

  let regChart;
  let payChart;
  export default {
    name: 'ChannelDataPage',
    data(){
      return{
        zUser:0,
        zPay:0,
        todayLogin:0,
        todayPay:0,
        keyChannel:'',
        currentPage:1,
        payCurrentPage:1,
        userListData:[],
        transactionListData:[],
        totalCount:0, //服务器返回总数量
        payTotalCount:0, //服务器返回总数量
      }
    },
    methods:{
      getList(){
        const formData = new FormData()
        formData.append('channel',this.keyChannel)
        formData.append('currentPage', this.currentPage)
        formData.append('pageCount', '10');
        $http('/channel_user_list',formData)
        .then(res =>{
            this.userListData = res['resource']
            this.totalCount = res['totalCount']
        })
      },
      getPayList(){
        const formData = new FormData()
        formData.append('channel',this.keyChannel)
        formData.append('currentPage', this.payCurrentPage)
        formData.append('pageCount', '10');
        $http('/channel_user_pay',formData)
        .then(res =>{
          this.transactionListData = res['resource']
          this.payTotalCount = res['totalCount']
        })
      },
      showUserSizeChange(current,size){
        this.currentPage = current
        this.getList()
      },
      showPaySizeChange(current,size)
      {
        this.payCurrentPage = current
        this.getPayList()
      },
      getTopList(){
        const formData = new FormData()
        formData.append('channel',this.keyChannel)
        $http('/get_channel_overview',formData)
            .then(res =>{
              let resource = res['resource']
              this.zUser = resource.userNum
              this.zPay = resource.vipNum
              this.todayLogin = resource.loginNum
              this.todayPay = resource.todayPay

              let dayList = []
              let android = []
              let ios = []
              let uniqueCount = []
              let todayReg = []
              for (let bean in resource.data)
              {
                dayList.push(bean)
                todayReg.push(resource.data[bean].count)
                android.push(resource.data[bean].AndroidCount)
                ios.push(resource.data[bean].iOSCount)
                uniqueCount.push(resource.data[bean].unique_count)
              }

              let payDayList = []
              let todayPay = []
              for (let bean in resource.payData)
              {
                payDayList.push(bean)
                todayPay.push(resource.payData[bean].count)
              }





            })

        $http('/get_login_count_for_channel',formData)
            .then(res =>{
              regChart.setOption({
                title: {
                  text: ''
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['注册量','去重后注册量']
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                toolbox: {
                  feature: {
                    saveAsImage: {}
                  }
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: res.resource[0].dayList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.resource[0].data
              });
            })

        $http('/get_pay_list_for_channel',formData)
            .then(res=>{
              payChart.setOption({
                title: {
                  text: ''
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['付费数']
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                toolbox: {
                  feature: {
                    saveAsImage: {}
                  }
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: res.resource[0].dayList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.resource[0].data[0],
              });
            })
      }
    },
    mounted() {
      if(this.$route.query['channel'])
      {
        this.keyChannel = this.$route.query['channel']
        this.getList()
        this.getPayList()
        this.getTopList()
      }
      else
      {
          ElMessage.error("非法状态")
      }

      regChart = echarts.init(document.getElementById('regChart'))
      payChart = echarts.init(document.getElementById('payChart'))



    }
  }
</script>

