<template>
 <div style="background-color: #283342;width: auto;height: 100vh;display: flex;justify-content: center;align-items: center">
   <div style="width: 500px;height: 300px;border-radius: 30px;display: flex;flex-direction: column;align-items: center">
    <el-input style="width: 80%;margin-top: 40px;height: 50px;background-color: #242e3b;--el-input-border-color:#3a434f;" v-model="username" placeholder="请输入用户名" />
    <el-input style="width: 80%;margin-top: 40px;height: 50px;background-color: #242e3b;--el-input-border-color:#3a434f;" v-model="password" placeholder="请输入密码" :visibility-toggle="false" type="password"/>
    <el-button style="margin-top: 40px;width: 80%;height: 50px" type="primary" :loading="isLoading" @click="login">登录</el-button>
   </div>
 </div>
</template>
<script>
import {$http} from "@/api";
import {useRouter}  from "vue-router";
import {ElMessage} from "element-plus";
export default {
    name:'LoginPage',
    data(){
        return {
            isLoading:false,
            username:'',
            password:'',
            router:useRouter()
        }
    },
    methods:{

        login(){
            if(this.username=='')
            {
                ElMessage.error('请输入用户名')
                return
            }
            if(this.password=='')
            {
                ElMessage.error('请输入密码')
                return
            }
            // this.isLoading = true
            const formData = new FormData();
            formData.append('username',this.username)
            formData.append('password',this.password)
            $http('/login',formData,this.isLoading)
                .then(res=>
                {
                    // this.isLoading=false
                    ElMessage({
                        message: '登录成功',
                        type: 'success',
                    })
                    this.router.push('/index/overviewPage')
                })
          this.templateName
        }
    }
}
</script>
<style>

</style>
