<template>
  <div style="padding: 20px">
    <div style="display: flex;justify-content: flex-end">
      <el-button type="primary" @click="addChannelModal">添加渠道</el-button>
    </div>
    <el-table stripe="true" border="true"  :data="channelDataList" size="large" style="height: 85%;width: 100%;margin-top: 20px">
      <el-table-column label="ID" prop="id" width="100px" align="center"/>
      <el-table-column label="应用名称" prop="name" width="200px" align="center"/>
      <el-table-column label="编号" prop="code" width="400px" align="center"/>
      <el-table-column label="渠道数据链接" prop="link" align="center"/>
      <el-table-column label="操作"  width="200px" align="center">
        <template #default="scope">
          <el-button type="danger" style="margin-left: 20px" @click="delChannel(scope.row.id)"  >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
  </div>

  <el-dialog
      v-model="modalVisible"
      width="600px"
      title="添加渠道"
      centered
      :footer="null"
  >
    <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>应用名称:</span>
                <el-input v-model="modalName" placeholder="请输入应用名称" style="width: 300px" />
            </span>

      <span class="DialogTv">
             <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="this.modalVisible=false">取消</el-button>
             <el-button type="danger" style="width: 40%" :loading="generateLinkLoading" @click="addChannel" danger>保存</el-button>
      </span>
    </div>
  </el-dialog>
</template>
<script>
  import {$http} from "@/api";
  import {ElMessage, ElMessageBox} from "element-plus";

  export default {
    name: 'ChannelPage',
    data(){
      return{
        channelDataList:[],
        currentPage: 1, //当前页码
        totalCount: 0, //服务器返回总数量
        modalVisible: false,//弹出窗
        generateLinkLoading:false,
        modalName:'',
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      // 获取数据
      getList(){
        const formData = new FormData();
        $http('/get_channel', formData)
            .then(res => {
              this.channelDataList = res['resource']
              this.totalCount = res['totalCount']
            })
      },
      // 渠道添加弹出框
      addChannelModal(){
        this.modalVisible = true;
        this.modalName = ''
      },
      // 添加渠道
      addChannel(){
        this.generateLinkLoading = true
        const formData = new FormData();
        formData.append('name',this.modalName);
        $http('/add_channel', formData)
            .then(res => {
              this.generateLinkLoading = false
              ElMessage({
                message: '添加成功',
                type: 'success',
              })
              this.modalVisible = false
              this.modalName = ''
              this.getList()
            })
      },
      // 删除渠道
      delChannel(id){
        let self = this;
        ElMessageBox.confirm(
            '确认删除该渠道？该操作不可逆',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        ).then(() => {
          const formData = new FormData();
          formData.append('id',id)
          $http('/del_channel', formData)
              .then(res=>{
                ElMessage({
                  message: '删除成功',
                  type: 'success',
                })
                self.getList()
              })
        }).catch(() => {

        })
      }
    }
  }
</script>
<style>

</style>