<template>
    <div style="padding: 20px;height: 100%">
        <div style="display: flex;justify-content: flex-end">
            <el-button type="primary" @click="addNodeModal">添加节点</el-button>
        </div>
        <el-table stripe="true" border="true"  :data="nodeListData" size="small" style="height: 80%;width: 100%;margin-top: 20px">
            <el-table-column label="节点名称" prop="name" width="300px" align="center"/>
            <el-table-column label="节点IP" prop="ip" width="300px" align="center"/>
            <el-table-column label="节点域名" prop="link" width="300px" align="center"/>
            <el-table-column label="节点类型" width="300px" align="center">
                <template #default="scope">
                    <span v-if="scope.row.type == '1'">
                      <el-tag type="success">正式节点</el-tag>
                    </span>
                    <span v-else-if="scope.row.type == '2'">
                      <el-tag type="danger">试用节点</el-tag>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="节点用户" prop="personCount" width="120px" align="center"/>
            <el-table-column label="节点连接数" prop="linkCount" width="120px" align="center"/>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button type="danger" style="margin-left: 20px" @click="delNode(scope.row.id)"  >删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="16" />
    </div>


    <el-dialog
        v-model="modalVisible"
        width="600px"
        title="添加节点"
        centered
        :footer="null"
    >
        <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>节点名称:</span>
                <el-input v-model="modalName" placeholder="请输入节点名称" style="width: 300px" />
            </span>
            <span class="DialogTv">
                <span>节点ip:</span>
                <el-input v-model="modalIp" placeholder="请输入节点ip" style="width: 300px" />
            </span>
            <span class="DialogTv">
                <span>节点域名:</span>
                <el-input v-model="modalLink" placeholder="请输入节点域名" style="width: 300px" />
            </span>
            <span class="DialogTv">
                <span>节点类型:</span>

                <el-select ref="select" v-model="modalType" style="width: 120px; margin-left: 20px" @focus="focus" @change="typeChange" >
                  <el-option
                          v-for="item in modalTypeOptions"
                          :key="item.value"
                          :label="item.lable"
                          :value="item.value"/>
                </el-select>

            </span>


            <div v-if="isFlash"/>
            <span class="DialogTv">

                 <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="this.modalVisible=false">取消</el-button>
                 <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="addNode" danger>保存</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
  import {$http} from "@/api";
  import {ElMessageBox} from "element-plus";
  import { ElMessage } from 'element-plus'

  export default {
      name:'NodeListPage',
      data(){
          return{

              nodeListData:[],
              currentPage:1, //当前页码
              totalCount:0, //服务器返回总数量
              modalVisible:false, // 节点弹出框
              modalName:'',// 添加节点名称
              modalIp:'',// 添加节点IP
              modalLink:'',// 添加节点域名
              modalType:'1',// 添加节点类型
              linkStatusMap:{},
              modalTypeOptions:[{value:'1',lable:"正式节点"},{value:'2',lable:"测试节点"}]

          }
      },
      mounted(){
          this.getNodeList()
      },
      methods:{
          //

          getNodeList(){
              const formData = new FormData();
              // 当前页码
              formData.append('currentPage',this.currentPage)
              // 页面大小
              formData.append('pageCount', '16');

              $http('/get_node_list',formData)
                  .then(res=>
                  {
                      this.nodeListData = res['resource']
                      this.totalCount = res['totalCount']
                      //
                      this.nodeListData.forEach(item => {
                          item['status'] = '测试中'
                      });
                      this.nodeTest()
                  })

          },
          // 页码回调
          showSizeChange(current,size){
                //设置当前页码
                this.currentPage = current
                this.getNodeList()
          },

          // 显示弹出框
          addNodeModal(){
              this.modalVisible = true
          },
          // 添加节点-节点类型选择回调
          typeChange(value){
              this.modalType = value
          },
          //节点探测
          nodeTest()
          {
              // this.nodeListData.forEach(item => {
              //     axios.post('https://'+item['link']+'/api/')
              //         .then(res=>{
              //             console.log(res)
              //         })
              //         .catch(e=>{
              //
              //         })
              // });
          },
          // 删除节点
          delNode(id)
          {
              let self = this;
              ElMessageBox.confirm(
                  '确认删除该节点？该操作不可逆',
                  '提示',
                  {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning',
                  }
              ).then(() => {
                  const formData = new FormData()
                  formData.append('id',id)

                  $http('/del_node',formData)
                      .then(res=>
                      {
                          ElMessage({
                              message: '删除成功',
                              type: 'success',
                          })
                          self.getNodeList()
                      })
              }).catch(() => {

              })


          },
          // 添加节点按钮
          addNode(){
              // 判断所有资源
              if(this.modalName=='')
              {
                  message.error('请输入节点名称')
                  return
              }
              if(this.modalIp=='')
              {
                  message.error('清输入节点ip')
                  return;
              }
              if(this.modalLink == '')
              {
                  message.error('请输入节点链接')
                  return;
              }
              if(this.modalLink.includes('http'))
              {
                  message.error('节点链接不能包含http:')
                  return;
              }

              const formData = new FormData();
              // 节点名称
              formData.append('name',this.modalName)
              // 节点ip
              formData.append('ip', this.modalIp);
              // 节点链接
              formData.append('link', this.modalLink)
              // 节点类型
              formData.append('type', this.modalType)

              //
              $http('/add_node',formData)
                  .then(res=>
                  {
                      ElMessage({
                          message: '添加成功',
                          type: 'success',
                      })
                      this.modalVisible = false
                      this.modalName = ''
                      this.modalIp = ''
                      this.modalLink = ''
                      this.modalType = '1'
                      this.getNodeList()
                  })
          }



      },
      // setup(){
      //     onMounted(()=>{
      //         this.getNodeList()
      //
      //     })
      // }

  }
</script>

<style>
.DialogTv{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>