<template>
  <div style="padding: 20px">
    <div style="display: flex;justify-content: flex-end">
      <el-button type="primary" @click="addNoticeModal">添加广告</el-button>
    </div>
    <el-table stripe="true" border="true"  :data="noticeDataList" size="large" style="height: 85%;width: 100%;margin-top: 20px">
      <el-table-column label="ID" prop="id" width="100px" align="center"/>
      <el-table-column label="渠道名称" prop="channel" width="200px" align="center"/>
      <el-table-column label="图片地址" prop="img" width="350px" align="center"/>
      <el-table-column label="跳转链接" prop="link" width="350px" align="center"/>
      <el-table-column label="内容" prop="content"  align="center"/>
      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <el-button type="danger" style="margin-left: 20px" @click="delNotice(scope.row.id)"  >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
  </div>

  <el-dialog
      v-model="modalVisible"
      width="600px"
      title="添加广告"
      centered
      :footer="null"
  >
    <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>应用渠道:</span>
                <el-input v-model="modalName" placeholder="请输入应用渠道" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>图片地址:</span>
                <el-input v-model="modalImg" placeholder="请输入图片地址" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>跳转地址:</span>
                <el-input v-model="modalLink" placeholder="请输入跳转地址" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>说明:</span>
                <el-input v-model="modalContent" placeholder="请输入说明" style="width: 300px"
                          :autosize="{ minRows: 5, maxRows: 50 }"
                          type="textarea"/>
            </span>


      <div v-if="isFlash"/>
      <span class="DialogTv">
                 <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="this.modalVisible=false">取消</el-button>
                 <el-button type="danger" style="width: 40%" :loading="generateLinkLoading" @click="addNotice" danger>保存</el-button>
      </span>
    </div>
  </el-dialog>

</template>

<script>
import {$http} from "@/api";
import {ElMessage, ElMessageBox} from "element-plus";
import {message} from "ant-design-vue";

export default {
  name:"adPage",
  data(){
    return{
      noticeDataList:[],
      currentPage:1, //当前页码
      totalCount:0, //服务器返回总数量
      modalVisible:false,//弹出窗
      modalName:'',
      modalImg:'',
      modalContent:'',
      modalLink:'',
      generateLinkLoading:false,
      changeModalVisible:false
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      const formData = new FormData();
      $http('/getAdList',formData)
          .then(res =>{
              this.noticeDataList = res['resource']
              this.totalCount = res['totalCount']
          })
    },
    addNoticeModal(){
      this.modalVisible = true
    },
    showSizeChange(){

    },
    addNotice(){

        // 判断所有资源
        if(this.modalName=='')
        {
          message.error('请输入应用渠道')
          return
        }
        if(this.modalImg=='')
        {
          message.error('清输入图片地址')
          return;
        }
        if(this.modalLink == '')
        {
          message.error('请输入跳转链接')
          return;
        }
      if(this.modalContent == '')
      {
        message.error('请输入说明')
        return;
      }

        const formData = new FormData();
        formData.append('channel',this.modalName)
        formData.append('img',this.modalImg)
        formData.append('link',this.modalLink)
        formData.append('content',this.modalContent)
        this.generateLinkLoading = true
        $http('/addAd',formData)
            .then(res=>{
              this.generateLinkLoading = false;
              this.modalVisible = false
              this.modalName = ''
              this.modalImg = ''
              this.modalLink = ''
              this.modalContent = ''

              ElMessage({
                message: '添加成功',
                type: 'success',
              })
              this.getList()
            })
    },
    changeNotice(){

      // 判断所有资源
      if(this.modalName=='')
      {
        message.error('请输入应用名称')
        return
      }
      if(this.modalVersion=='')
      {
        message.error('清输入应用版本')
        return;
      }
      if(this.modalContent == '')
      {
        message.error('请输入公告内容')
        return;
      }

      const formData = new FormData();
      formData.append('id',this.modalId)
      formData.append('name',this.modalName)
      formData.append('version',this.modalVersion)
      formData.append('content',this.modalContent)
      this.generateLinkLoading = true
      $http('/change_notice',formData)
          .then(res=>{
            this.generateLinkLoading = false;
            this.changeModalVisible = false
            this.modalId = ''
            this.modalName = ''
            this.modalVersion = ''
            this.modalContent = ''

            ElMessage({
              message: '修改成功',
              type: 'success',
            })
            this.getList()
          })
    },
    changeNoticeModal(data){
        this.modalId = data.id
        this.modalName = data.appname;
        this.modalVersion = data.appversion;
        this.modalContent = data.gonggao;
        this.changeModalVisible = true
    },
    delNotice(id){
      let self = this;
      ElMessageBox.confirm(
          '确认删除该广告？该操作不可逆',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        const formData = new FormData()
        formData.append('id',id)

        $http('/delAd',formData)
            .then(res=>
            {
              ElMessage({
                message: '删除成功',
                type: 'success',
              })
              self.getList()
            })
      }).catch(() => {

      })
    }
  }
}
</script>
