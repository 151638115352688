<template>
    <div style="padding: 20px">
        <el-table stripe="true" border="true"  :data="nodeListData" size="large" style="height: 85%;width: 100%;">
            <el-table-column label="名称" prop="name" width="200px" align="center"/>
            <el-table-column label="IP" prop="ip" width="150px" align="center"/>
            <el-table-column label="CPU" width="220px" align="center">
                <template #default="scope">
                    <el-progress
                        :percentage="scope.row.cpu"
                        :stroke-width="15"
                        striped
                        striped-flow
                        :duration="10"
                    />
                </template>
            </el-table-column>
            <el-table-column label="内存"  width="220px" align="center">
                <template #default="scope">
                    <el-progress
                        :percentage="scope.row.memory"
                        :stroke-width="15"
                        striped
                        striped-flow
                        :duration="10"
                    />
                </template>
            </el-table-column>
            <el-table-column label="硬盘" prop="dick" width="220px" align="center">
                <template #default="scope">
                    <el-progress
                        :percentage="scope.row.dick"
                        :stroke-width="15"
                        striped
                        striped-flow
                        :duration="10"
                    />
                </template>
            </el-table-column>
            <el-table-column label="连接数" prop="total" width="100px" align="center"/>
            <el-table-column label="上传KB" prop="up" width="100px" align="center"/>
            <el-table-column label="下载KB" prop="down" width="100px" align="center"/>
            <el-table-column label="总上传" prop="zup" width="100px" align="center"/>
            <el-table-column label="总下载" prop="zdown" width="100px" align="center"/>
            <el-table-column label="证书" prop="ssl" align="center"/>
        </el-table>
    </div>


</template>

<script>
import {$http} from "@/api";

export default {
    name:'NodeInfoPage',

    data(){
        return{
            columns:[
                {title:'名称',key:'name',dataIndex: 'name'},
                {title:'IP',key: 'ip',dataIndex: 'ip',width:150},
                {title:'CPU',key: 'cpu',dataIndex: 'cpu',width:350},
                {title:'内存',key: 'memory',dataIndex: 'memory',width:350},
                {title:'硬盘',key: 'dick',dataIndex: 'dick',width:350},
                {title:'连接数',key: 'total',dataIndex: 'total',width:150},
                {title:'上传KB',key: 'up',dataIndex: 'up',width:150},
                {title:'下载KB',key: 'down',dataIndex: 'down',width:150},
                {title:'证书',key: 'ssl',dataIndex: 'ssl',width:250},
            ],
            nodeListData:[],
        }
    },
    mounted(){
        // if()
        this.getNodeInfo()
    },
    methods:{
        // 获取节点信息
        getNodeInfo() {

            $http('/get_node_info', null)
                .then(res => {
                    this.nodeListData = res['resource']

                    // this.nodeListData['zdown2'] =
                    // this.nodeListData['zup2'] = res['resource']['zup']/1024/1024/1024
                    for(let index in this.nodeListData){

                      this.nodeListData[index]['zup'] = (Number(this.nodeListData[index]['zup']/1024/1024/1024) || 0).toFixed(2)+" GB"
                      this.nodeListData[index]['zdown'] = (Number(this.nodeListData[index]['zdown']/1024/1024/1024) || 0).toFixed(2)+" GB"
                    }

                })


        }

    }
}

</script>