<template>
    <div style="padding-top: 20px;padding-left:20px;padding-right:20px;display: flex;margin-bottom: 20px;align-items: center;">
        <el-input v-model="keyUserId" placeholder="请输入用户ID" style="width: 200px"  clearable/>
        <el-input v-model="keyUserName" placeholder="请输入账号" style="width: 200px;margin-left:20px "  clearable/>
        <el-input v-model="keyDeviceId" placeholder="请输入设备ID" style="width: 200px;margin-left: 20px" clearable/>

        <span style="margin-left: 10px; font-size: 14px">会员状态</span>
        <el-select ref="select" v-model="keyIsVip" style="width: 120px; margin-left: 20px" @focus="focus" @change="keyIsVipChange" >
          <el-option
              v-for="item in vipOptions"
              :key="item.value"
              :label="item.lable"
              :value="item.value"/>
        </el-select>
      <div v-if="isFlash"/>
        <span style="margin-left: 10px;font-size: 14px">系统</span>
        <el-select ref="select" v-model="keyIsSys" style="width: 120px; margin-left: 20px" @focus="focus" @change="keyIsSysChange" >
            <el-option
                v-for="item in sysOptions"
                :key="item.value"
                :label="item.lable"
                :value="item.value"/>
        </el-select>
        <span style="margin-left: 10px;font-size: 14px">渠道</span>
        <el-select v-if="isFlash" ref="select" v-model="keyChannel" style="width: 120px; margin-left: 20px" @focus="focus" @change="keyIsChannel" >
          <el-option
              v-for="item in syskeyChannelOptions"
              :key="item.value"
              :label="item.lable"
              :value="item.value"/>
        </el-select>
        <span style="margin-left: 10px;font-size: 14px">注册时间</span>
        <el-date-picker

            style="margin-left: 10px;width: 200px" v-model='defaultKeyTime' type="daterange"
                        range-separator="到"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间" format="YYYY-MM-DD" showToday="true" @change="onRangeTimeChange"/>
        <span style="margin-left: 10px;font-size: 14px">最后登录时间</span>
        <el-date-picker style="margin-left: 10px;width: 200px" v-model='defaultKeyLoginTime' type="daterange"
                        range-separator="到"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间" @change="onRangeLoginTimeChange" format="YYYY-MM-DD" showToday="true" />
      <el-switch
          v-model="showRefunds"
          class="ml-2"
          inline-prompt
          size="large"
          style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949;align-content: space-evenly;margin-left: 20px"
          active-text="查看所有"
          inactive-text="只看退款"
      />
        <el-button style="align-content: space-evenly;margin-left: 20px" type="primary" @click="seachUserList">查询</el-button>
        <el-button style="align-content: space-evenly;margin-left: 20px" type="danger" plain @click="clearKey">清除</el-button>
    </div>
    <div style="padding-left: 20px;padding-right: 20px;height:75%">
        <el-table stripe="true" border="true"  :data="userListData" size="large"  style="height: 100%;width: 100%;">
            <el-table-column label="用户名" prop="userName"  align="center" width="200"/>
            <el-table-column label="设备ID" prop="deviceId" width="330px" align="center"/>
            <el-table-column label="会员" width="100px" align="center">
                <template #default="scope">
                <span v-if="scope.row.isVip">
                    <el-tag type="warning">会员</el-tag>
                </span>
                </template>
            </el-table-column>
            <el-table-column label="开通日期" prop="vipOpenTime"  align="center"/>
            <el-table-column label="到期时间" prop="vipOutTime"  align="center"/>
            <el-table-column label="套餐"  align="center" width="200">
                <template #default="scope">
                    <template v-if="scope.row.vipOpenTime != ''">
                    <span v-if="scope.row.vipType == 'month'">
                        <el-tag type="success">月卡</el-tag>
                    </span>
                    <span v-else-if="scope.row.vipType == 'year'">
                        <el-tag type="success">年卡</el-tag>
                    </span>
                    <span v-else-if="scope.row.vipType == 'week'">
                        <el-tag type="success">周卡</el-tag>
                    </span>
                    <span v-else-if="scope.row.vipType == 'noyear'">
                         <el-tag type="success">半年卡</el-tag>
                    </span>
                    <span v-else-if="scope.row.vipType == 'season'">
                        <el-tag type="success">季卡</el-tag>
                    </span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="渠道" prop="channel" align="center" width="200"/>
            <el-table-column label="注册时间" prop="regisTime" align="center" width="200"/>
            <el-table-column label="最后登录时间" prop="loginTime" align="center" width="200"/>
          <el-table-column label="退款次数" prop="refunds" align="center" width="200"/>
            <el-table-column label="操作" align="center" >
                <template #default="scope">
                    <span v-if="scope.row.userStatus == 'block'">
                        <el-tag type="danger">已封禁</el-tag>
                    </span>
                    <span v-else>
                        <el-button type="primary" @click="showUserDetails(scope.row)">查看</el-button>
                        <el-button type="danger" style="margin-left: 20px" @click="delUser(scope.row.userId)"  >删除</el-button>
                      <el-button type="warning" style="margin-left: 20px" @click="blockUser(scope.row.deviceId)"  >加黑</el-button>
                    </span>

                </template>
            </el-table-column>

        </el-table>
    </div>

    <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
    <span style="font-size: 15px;height: 40px">共{{totalCount}}条数据</span>

    <el-dialog
            v-model="modalVisible"
            width="600px"
            title="用户信息"
            centered
            :footer="null"
    >
        <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>用户id:</span>
                <span>{{userModel.userId}}</span>
            </span>
            <span class="DialogTv">
                <span>用户名:</span>
                <span>{{userModel.userName}}</span>
            </span>
            <span class="DialogTv">
                <span>设备ID:</span>
                <span>{{userModel.deviceId}}</span>
            </span>
            <span class="DialogTv">
                <span>系统:</span>
                <span>{{userModel.system}}</span>
            </span>
            <span class="DialogTv">
                <span>最后登录IP:</span>
                <span>{{userModel.ip}}</span>
            </span>
            <span class="DialogTv">
                <span>应用:</span>
                <span>{{userModel.channel}}</span>
            </span>
            <span class="DialogTv">
                <span>会员状态:</span>
                 <el-select ref="select" v-model="userModelIsVip" style="width: 120px; margin-left: 20px" @focus="focus" @change="vipChange" >
                  <el-option
                      v-for="item in ModelIsVipOptions"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"/>
                </el-select>
            </span>
            <span class="DialogTv">
                <span>会员到期时间:</span>
                 <el-date-picker
                     format="YYYY-MM-DD HH:mm:ss"
                     v-model="vipOutTime"
                     type="datetime"
                     placeholder="Select date and time"
                 />
<!--                <a-date-picker v-model:value="vipOutTime" show-time placeholder="" @change="onVipDateChange" />-->
            </span>
            <span class="DialogTv">
                <span>套餐类型:</span>
                <el-select ref="select" v-model="vipType" style="width: 120px; margin-left: 20px" @focus="focus" @change="vipTypeChange" >
                  <el-option
                      v-for="item in ModeIsTypeOptions"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"/>
                </el-select>

            </span>
            <span class="DialogTv">
                <span>注册时间:</span>
                <span>{{userModel.regisTime}}</span>
            </span>
            <span class="DialogTv">
                <span>最后登录时间:</span>
                <span>{{userModel.loginTime}}</span>
            </span>
            <span class="DialogTv">
                <span>订阅链接:</span>
                <el-input
                        v-model="userSubscription"
                        placeholder=""
                        :autosize="{ minRows: 2, maxRows: 5 }"
                        type="textarea"
                />
            </span>
            <div v-if="isFlash"/>
            <span class="DialogTv">
                 <el-button type="primary" style="width: 30%" :loading="generateLinkLoading" @click="generateLink(userModel.userId)" >重新生成订阅链接</el-button>
                <el-button type="primary" style="width: 20%" :loading="generateLinkLoading" @click="router.push('/index/userPayList?userId='+userModel.userId)">查看订单</el-button>
              <el-button type="primary" style="width: 20%" :loading="generateLinkLoading" @click="router.push('/index/UserFlowPage?userId='+userModel.userId)">查看流量</el-button>
                 <el-button type="primary" style="width: 20%" :loading="generateLinkLoading" @click="closeUserDialog">取消</el-button>
                 <el-button type="danger" style="width: 20%" :loading="generateLinkLoading" @click="saveUser" >保存</el-button>
            </span>
        </div>
    </el-dialog>


</template>
<script setup>
  import {$http} from "@/api";
  import {onMounted, ref} from "vue";
  import dayjs from "dayjs";

  import {useRouter}  from "vue-router";
  import {ElMessageBox} from "element-plus";
  import { ElMessage } from 'element-plus'
  // import app from "@/App.vue";
  const router = useRouter()
  const value = ""
  const vipOptions=[{value:'',lable:"所有"},{value:'1',lable:"会员"},{value:'0',lable:"非会员"}]
  const sysOptions=[{value:'-1',lable:"所有"},{value:'1',lable:"Android"},{value:'2',lable:"iOS"}]
  const ModelIsVipOptions=[{value:'0',lable:"否"},{value:'1',lable:"是"}]
  const ModeIsTypeOptions=[{value:'no',lable:"无"},{value:'month',lable:"月卡"},{value:'week',lable:"周卡"},{value:'season',lable:"季卡"},{value:'noyear',lable:"半年卡"},{value:'year',lable:"年卡"}]
  const syskeyChannelOptions=ref([{value:'-1',lable:"所有"}])
  const value1 = ref('')
  const columns = [

      {
          title: '会员',
          dataIndex: 'vip',
          key: 'isVip',
      },
      {
          title: '开通日期',
          key: 'vipOpenTime',
          dataIndex: 'vipOpenTime',
      },
      {
          title: '到期时间',
          key: 'vipOutTime',
          dataIndex: 'vipOutTime',
      },
      {
          title: '套餐',
          key: 'vipType',
          dataIndex: 'vipType',
      },
      {
          title: '注册时间',
          key: 'regisTime',
          dataIndex: 'regisTime',
      },
      {
          title: '最后登录时间',
          key: 'loginTime',
          dataIndex: 'loginTime',
      },
      {
          title: '操作',
          key: 'action',
      },
  ];

  // 页码回调
  // eslint-disable-next-line no-unused-vars
  const showSizeChange = (current,size) => {
      currentPage.value = current
      seachUserList()
  }
  // 搜索用
  let keyUserId = ref('')
  let keyUserName = ref('')
  let keyDeviceId = ref('')
  let keyIsVip = ref('')
  let keyIsSys = ref('-1')
  let keyChannel = ref('-1')
  let keyStartTime = ref('')
  let keyEndTime = ref('')
  let keyStartLoginTime = ref('')
  let keyEndLoginTime = ref('')
  let defaultKeyTime = ref([])
  let defaultKeyLoginTime = ref([])
  let showRefunds = ref(true)
  //
  const userListData = ref([])
  const currentPage = ref(1)
  const totalCount = ref(0)
  const modalVisible = ref(false)
  // 弹出框内容
  let userModel = ref({})
  let userModelIsVip = ref('否')
  let vipOutTime = ref('2020-01-01')
  let vipType = ref('无')
  let userSubscription = ref('')
  let generateLinkLoading = ref(false)
  let isFlash = ref(true)

  const keyIsVipChange = (value) => {
      keyIsVip.value = value
  }
  const keyIsSysChange = (value) => {
      keyIsSys.value = value
  }
  const keyIsChannel = (value) => {
      keyChannel.value = value
  }
  // 筛选时间回调
  const onRangeTimeChange = (value, dateString) => {

      if(value == null)
      {
          keyStartTime.value = ''
          keyEndTime.value=''
      }
      else
      {
          keyStartTime.value = dayjs(new Date(defaultKeyTime.value[0])).format('YYYY-MM-DD')
          keyEndTime.value = dayjs(new Date(defaultKeyTime.value[1])).format('YYYY-MM-DD')
      }

  }

  const onRangeLoginTimeChange = (value, dataString) => {
      if(value == null)
      {
          keyStartLoginTime.value = ''
          keyEndLoginTime.value=''
      }
      else
      {
          keyStartLoginTime.value = dayjs(new Date(defaultKeyLoginTime.value[0])).format('YYYY-MM-DD')
          keyEndLoginTime.value = dayjs(new Date(defaultKeyLoginTime.value[1])).format('YYYY-MM-DD')
      }
  }

  // 用户详情更改套餐时间回调
  // eslint-disable-next-line no-unused-vars
  const onVipDateChange = (date, dateString) =>
  {
      isFlash.value = false
      vipOutTime = date
      setTimeout(()=>
      {
          isFlash.value=true
      },200)

  }
  // 用户详情更改vip状态回调
  const vipChange = (value) => {
      if (value == 1)
      {
          userModelIsVip.value = '是'
          // userModel.value.isVip = 1
      }
      else
      {
          userModelIsVip.value = '否'
          // userModel.value.isVip = 0
      }
  };
  const getChannelList = () => {

    const formData = new FormData();
    $http('/get_channel',formData)
        .then(res=>{

          setTimeout(()=>
          {
            isFlash.value=false
          },200)

          let resource = res['resource']

          syskeyChannelOptions.value = []

          let ds = [{value:'-1',lable:"所有"}]

          for (let bean in resource)
          {
            ds.push({value:resource[bean].name,lable:resource[bean].name})
          }

          syskeyChannelOptions.value = ds



          setTimeout(()=>
          {
            isFlash.value=true
          },200)

        })
  }
  // 用户详情更改套餐类型回调
  const vipTypeChange = (value) => {

      switch (value){
          case 'no':
              vipType.value = '无'
              break
          case 'month':
              vipType.value = '月卡'
              break
          case 'week':
              vipType.value = '周卡'
              break
          case 'season':
              vipType.value = '季卡'
              break
          case 'noyear':
              vipType.value = '半年卡'
              break
          case 'year':
              vipType.value = '年卡'
              break
      }
  }
  // 关闭用户详情
  const closeUserDialog = () => {
      modalVisible.value=false

  }
  // 保存用户详情
  const saveUser = () =>
  {

      const formData = new FormData();
      // 用户id
      formData.append('userId',userModel.value.userId)
      //
      formData.append('vipOutTime',vipOutTime.value==''?'':dayjs(new Date(vipOutTime.value)).format('YYYY-MM-DD HH:mm:ss'))
      //
      formData.append('vipType',vipType.value)
      // link
      formData.append('subscription',userSubscription.value)
      //
      formData.append('isVip',userModelIsVip.value=='是'?1:0)
      ElMessageBox.confirm(
          '确认保存？该操作不可逆',
          '提示',
          {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
          }
      ).then(() => {
          generateLinkLoading.value = true
          $http('/updateUser',formData,generateLinkLoading)
              .then(res=>
              {
                  generateLinkLoading.value = false
                  seachUserList()
                  ElMessage({
                      message: '保存成功',
                      type: 'success',
                  })
                  modalVisible.value = false
              })
      }).catch(() => {

      })

  }
  // 重新生成订阅
  const generateLink = (userId) =>
  {
      ElMessageBox.confirm(
          '确认重新生成订阅?该操作不可逆',
          '提示',
          {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
          }
      ).then(() => {
          generateLinkLoading.value = true
          const formData = new FormData();
          // 当前页码
          formData.append('id',userId)

          $http('/generate_link',formData,generateLinkLoading)
              .then(response => {
                  // 获取订阅
                  $http('/get_subscription',formData,generateLinkLoading)
                      .then(responses =>
                      {
                          generateLinkLoading.value = false
                          ElMessage({
                              message: '生成',
                              type: 'success',
                          })
                          userModel.value.subscription = '['+responses['resource']+']'
                          userSubscription.value = userModel.value.subscription
                      })
              });
      }).catch(() => {

      })




  }
  // 加黑用户
  const blockUser = (deviceId) => {
    ElMessageBox.confirm(
        '确认加黑该用户?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
    ).then(() => {
      // generateLinkLoading.value = true
      const formData = new FormData();
      // 当前页码
      formData.append('deviceId',deviceId)

      $http('/set_block',formData,generateLinkLoading)
          .then(response => {
            ElMessage({
              message: '加黑成功',
              type: 'success',
            })
            seachUserList()
          });
    }).catch(() => {

    })

  }
  // 删除用户
  const delUser = (id) =>
  {
      ElMessageBox.confirm(
          '确认删除该用户?该操作不可逆',
          '提示',
          {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
          }
      ).then(() => {
          // generateLinkLoading.value = true
          const formData = new FormData();
          // 当前页码
          formData.append('id',id)

          $http('/del_user',formData,generateLinkLoading)
              .then(response => {
                  ElMessage({
                      message: '删除成功',
                      type: 'success',
                  })
                  seachUserList()
              });
      }).catch(() => {

      })


  }
  // 弹出框按钮逻辑
  const showUserDetails = (user) =>
  {

      userModel.value = user
      //  vip状态
      if(user.isVip == 1){
          userModelIsVip.value = '是'
      }
      else
      {
          userModelIsVip.value = '否'
      }
      // 套餐
      switch (user.vipType){
          case 'month':
              vipType.value = '月卡'
              break
          case 'season':
              vipType.value = '季卡'
              break
          case 'noyear':
              vipType.value = '半年卡'
              break
          case 'week':
              vipType.value = '周卡'
              break
          case 'year':
              vipType.value = '年卡'
              break
          default:
              vipType.value = '无'
      }
      // vip到期时间
      if (userModel.value.vipOutTime !== '')
      {
          vipOutTime.value = dayjs(userModel.value.vipOutTime)
      }
      else
      {
          vipOutTime.value = ''
      }
      // 订阅

      getSubscription(userModel.value.userId)

      modalVisible.value = true

  }


  // 清除条件
  const clearKey = () => {
      keyUserId.value = ''
      keyUserName.value = ''
      keyDeviceId.value = ''
      keyIsVip.value = ''
      keyIsSys.value = '-1'
      keyStartTime.value = ''
      keyEndTime.value = ''
      keyStartLoginTime.value = ''
      keyEndLoginTime.value = ''
      defaultKeyTime.value = []
      defaultKeyLoginTime.value = []
      seachUserList()
  }

  //
  const getSubscription = (id)=>{
    const formData = new FormData();
    formData.append('id',id)
    $http('/get_subscription',formData,generateLinkLoading)
        .then(response => {
          // this.data = response;
          // return
          userSubscription.value = response['resource']
          // console.log(response)
        })
  }

  // Http请求数据
  const seachUserList = () => {

      // console.log(defaultKeyTime.value)
      // const dData = {
      //     'totalCount':20
      // }
      const formData = new FormData();
      // 当前页码
      formData.append('currentPage',currentPage.value)
      // 页面大小
      formData.append('pageCount', '12');
      // 关键词 用户名
      formData.append('keyUserName',keyUserName.value)
      // 关键词 用户ID
      formData.append('userId',keyUserId.value)
      // 关键词 设备号
      formData.append('keyDeviceId', keyDeviceId.value)
      // 关键词 是否会员
      formData.append('isVip',keyIsVip.value)
      // 关键词 系统
      formData.append('sys',keyIsSys.value==-1?'':keyIsSys.value==1?'Android':'iOS')
      // 关键词 开始时间
      formData.append('startTime',keyStartTime.value)
      // 关键词 结束时间
      formData.append('endTime',keyEndTime.value)
      // 关键词 登录开始时间
      formData.append('startLoginTime',keyStartLoginTime.value)
      // 关键词 登录结束时间
      formData.append('endLoginTime',keyEndLoginTime.value)
      // 关键词 查看所有
      formData.append('refunds',showRefunds.value?"0":"1")
      // 关键词 渠道
      formData.append('channel',keyChannel.value)
      $http('/get_user_list',formData,generateLinkLoading)
          .then(response => {
              // this.data = response;
              userListData.value = response['resource']
              //
              totalCount.value = response['totalCount']

              // console.log(response)
          })

  };



  onMounted(() => {
      getChannelList()
      seachUserList()
  });


</script>

<style>
  .DialogTv{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
  }
</style>