<template>
  <div style="padding: 20px">
    <div style="display: flex;justify-content: flex-end">
      <el-button type="primary" @click="addPayTypeModal">添加支付类型</el-button>
    </div>
    <el-table stripe="true" border="true"  :data="payDataList" size="large" style="height: 85%;width: 100%;margin-top: 20px">
      <el-table-column label="ID" prop="id" width="100px" align="center"/>
      <el-table-column label="应用名称" prop="appname" width="200px" align="center"/>
      <el-table-column label="应用版本" prop="appversion" width="200px" align="center"/>
      <el-table-column label="第三方支付开关"  align="center" >
        <template #default="scope">
          <el-switch
              v-model="scope.row.check"
              @change="changeSwitch(scope.row)"
              active-value=1
              inactive-value=0
              class="ml-2"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <el-button type="danger" style="margin-left: 20px" @click="delPayType(scope.row.id)"  >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top: 20px;margin-bottom:20px;justify-content: center"  background layout="prev, pager, next" :total="totalCount" v-model:current-page="currentPage" @change="showSizeChange" :page-size="12" />
  </div>

  <el-dialog
      v-model="modalVisible"
      width="600px"
      title="添加支付类型"
      centered
      :footer="null"
  >
    <div style="display: flex;flex-direction: column;">
            <span class="DialogTv">
                <span>应用名称:</span>
                <el-input v-model="modalName" placeholder="请输入应用名称" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>应用版本:</span>
                <el-input v-model="modalVersion" placeholder="请输入应用版本" style="width: 300px" />
            </span>
      <span class="DialogTv">
                <span>第三方支付:</span>
                <el-switch
                    v-model="modalSwitch"
                    active-value=1
                    inactive-value=0
                    class="ml-2"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                />
            </span>


      <div v-if="isFlash"/>
      <span class="DialogTv">
             <el-button type="primary" style="width: 40%" :loading="generateLinkLoading" @click="this.modalVisible=false">取消</el-button>
             <el-button type="danger" style="width: 40%" :loading="generateLinkLoading" @click="addPayType" danger>保存</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import {$http} from "@/api";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name:"PayPage",
  data(){
    return{
      payDataList: [],
      currentPage: 1, //当前页码
      totalCount: 0, //服务器返回总数量
      modalVisible: false,//弹出窗
      modalName: '',
      modalVersion: '',
      modalSwitch: '0',
      generateLinkLoading: false,
      changeModalVisible: false,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
      getList(){
        const formData = new FormData();
        $http('/get_pay_type_list', formData)
            .then(res => {
              this.payDataList = res['resource']
              this.totalCount = res['totalCount']
            })
      },

      changeSwitch(row){
          const formData = new FormData();
          formData.append('id',row.id)
          formData.append('check',row.check)

          $http('/update_pay_type', formData)
            .then(res => {
              ElMessage({
                message: '修改成功',
                type: 'success',
              })
              this.getList()
            })
      },
      addPayType(){
        this.generateLinkLoading = true
        const formData = new FormData();
        formData.append('name',this.modalName)
        formData.append('version',this.modalVersion)
        formData.append('check',this.modalSwitch)

        $http('/add_pay_type', formData)
            .then(res=>{
              this.generateLinkLoading = false
                ElMessage({
                  message: '添加成功',
                  type: 'success',
                })
                this.modalVisible = false
                this.modalName = ''
                this.modalVersion = ''
                this.modalSwitch = '0'
                this.getList()
            })
      },
      addPayTypeModal(){
          this.modalVisible = true
      },
      delPayType(id){


        let self = this;
        ElMessageBox.confirm(
            '确认删除该支付方式？该操作不可逆',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        ).then(() => {
          const formData = new FormData();
          formData.append('id',id)
          $http('/del_pay_type', formData)
              .then(res=>{
                ElMessage({
                  message: '删除成功',
                  type: 'success',
                })
                self.getList()
              })
        }).catch(() => {

        })
      }


  }
}
</script>
